<template>
	<v-container>
		<v-row>
			<v-col cols="12" class="text-center">
				<h1>
					Driver Quick Scan
				</h1>
				<v-divider></v-divider>
			</v-col>

			<v-col cols="12" v-if="loading">
				<dashboard-own-scan-loading scan />
			</v-col>

			<v-col cols="12" v-else-if="rapportAvailable">
				<v-row>
					<v-col cols="12">
						<pdf :src="imageUrl" height="50px"
						></pdf>
					</v-col>
					<v-col cols="12" class="text-center">
						<v-divider></v-divider>

						<p class="font-weight-regular">
							Wat zijn mijn specifieke controlemechanismes en in welke mate vormen deze een drijvende kracht
							dan wel een rem op de toegang tot mijn talenten?
						</p>
						<p class="font-weight-regular">
							Jouw meest dominante Drivers zijn in volgorde:
							{{ driversTop[0] }} | {{ driversTop[1] }} | {{ driversTop[2] }}
						</p>
						<p class="font-weight-regular">
							{{ description }}
						</p>
					</v-col>

					<v-col cols="12" class="text-center py-2">
						<v-btn color="primary" block @click="downloadDriverPdf" :loading="downloadPdfLoading" :disabled="downloadPdfLoading">
							Download driver uitleg
						</v-btn>
					</v-col>

					<v-col cols="12" class="text-center py-2">
						<v-btn color="primary" block @click="downloadRapport" :loading="downloadLoading" :disabled="downloadLoading">
							Download volledig profiel
						</v-btn>
					</v-col>

					<v-col cols="12" class="text-center" v-if="this.driverScans.length > 1">
						<v-divider></v-divider>
						<h3>Driver Quick Scan - historie</h3>

						<v-simple-table :loading="true">
							<template v-slot:default>
								<thead>
								<tr>
									<th class="text-left">
										Datum
									</th>
									<th class="text-left">
										Status
									</th>
								</tr>
								</thead>
								<tbody>
								<tr
									v-for="driverScan in driverScans"
									:key="driverScan.id"
								>
									<td class="text-left">{{ driverScan.created_at }}</td>
									<td class="text-left">
										<v-icon color="green" v-if="driverScan.started && driverScan.completed">fa fa-check-circle</v-icon>
										<v-btn color="primary ml-2" v-if="driverScan.started && driverScan.completed" @click="downloadRapportById(driverScan.id)" :loading="downloadLoading" :disabled="downloadLoading">
											Downloaden
										</v-btn>
										<v-icon color="accent" v-if="driverScan.started && !driverScan.completed">fa fa-hourglass-start</v-icon>
										<v-icon color="red" v-if="!driverScan.started">fa fa-times-circle</v-icon>
									</td>
								</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>


				</v-row>
			</v-col>

			<v-col cols="12" v-else>
				<dashboard-own-scan-not-filled scan />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

import DashboardOwnScanNotFilled from "@/pages/dashboard/own/notfilled";
import pdf from 'vue-pdf'
import DashboardOwnScanLoading from "@/pages/dashboard/own/loading";
import {mapGetters} from "vuex";
import apiClient from "@/services/apiClient";
import axios from "axios";

export default {
	name: "dashboardOwnScanDQS",
	components: {
		DashboardOwnScanLoading,
		DashboardOwnScanNotFilled,
		pdf
	},
	data() {
		return {
			loading: true,
			rapportAvailable: false,
			imageUrl: "",
			downloadUrl: "",
			downloadPdfLoading: false,
			downloadLoading: false,
			driversTop: [],
			description: "",
			driverScans: []
		}
	},
	computed: {
		...mapGetters({
			token: "auth/accessToken",
			scan: "user/scan",
			user: "user/user"
		}),
	},
	mounted() {
		this.driverScans = this.scan.dqs.scans;

		if (this.scan.dqs.access && this.scan.dqs.completed) {
			this.rapportAvailable = true;

			this.imageUrl = process.env.VUE_APP_API_URL + "scan/dqs/image?token=" + this.token;
			this.downloadUrl = process.env.VUE_APP_API_URL + "scan/dqs/download.pdf?token=" + this.token;

			apiClient.get("scan/dqs/data").then(response => {
				this.showSuccess = true;

				this.driversTop = response.data.driversTop;
				this.description = response.data.text;

				this.loading = false;
			}).catch(error => {
				this.loading = false;
			});
		} else {
			this.loading = false;
			this.rapportAvailable = false;
		}
	},
	methods: {
		stopLoadingDriverPdf() {
			this.downloadPdfLoading = false;
		},
		downloadDriverPdf() {
			this.downloadPdfLoading = true;

			window.open(process.env.VUE_APP_BASE_URL + "driver.pdf", "_blank");

			setTimeout(this.stopLoadingDriverPdf, 2000);
		},
		stopLoading() {
			this.downloadLoading = false;
		},
		downloadRapport() {
			this.downloadLoading = true;

			window.open(this.downloadUrl, "_blank");

			setTimeout(this.stopLoading, 2000);
		},
		downloadRapportById(scanId) {
			this.downloadLoading = true;

			let downloadUrl = process.env.VUE_APP_API_URL + "scan/dqs/" + scanId + "/download.pdf?token=" + this.token;

			window.open(downloadUrl, "_blank");

			setTimeout(this.stopLoading, 2000);
		},
	}
};
</script>